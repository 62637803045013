import { hot } from "react-hot-loader/root";
import React, { useState, Suspense } from "react";
import ContextOffcanvas from "./contexts/offcanvas-context";
import ContextUser from "./contexts/user-context";
import ComponentSiteheader from "./layouts/siteheader/index";
import ComponentSitefooter from "./layouts/sitefooter/index";
import ComponentSitefooterTKL from "./layouts/sitefootertkl/index";
import ComponentSiteheaderTKL from "./layouts/siteheadertkl";

import ComponentSitenav from "./layouts/sitenav/index";
import ComponentOffcanvas from "./offcanvas/index";
import ComponentContentLoader from "./shared-components/page-loader/index-content";
//  import OffcanvasUserAccount from "./offcanvas/templates/user-account"
import { withRouter } from "react-router-dom";
import { IS_UNDER_MAINTENANCE } from "./api/tklEnvironments";
import {
  _AddClass,
  _HasClass,
  _RemoveClass,
} from "./shared/services/constants";

import 'react-image-crop/dist/ReactCrop.css'
import history from "./Routes/history";
import MainRoutes from "./Routes/mainRoutes";
import "./App.scss";
import { Provider, connect } from "react-redux";
import configureStore from "./store/index";
import { getProfileData } from "./actions/UserActions/ProfileActions";
import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "./actions/Notification/NotificationActions";
import LogRocket from "logrocket";
export const store = configureStore({});
const OffcanvasChangeEmail = React.lazy(() =>
  import("./offcanvas/templates/change-email")
);
const OffcanvasChangePassword = React.lazy(() =>
  import("./offcanvas/templates/change-password")
);
const OffcanvasCourseSettings = React.lazy(() =>
  import("./offcanvas/templates/course-settings")
);
const OffcanvasCreateCohort = React.lazy(() =>
  import("./offcanvas/templates/create-cohort")
);
const OffcanvasCatalogFilters = React.lazy(() =>
  import("./offcanvas/templates/catalog-filters")
);
const OffcanvasUserAccount = React.lazy(() =>
  import("./offcanvas/templates/user-account")
);

// import TKLErrorBoundary from "./TKLErrorBoundary";

function App(props) {
  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.notifications);

  React.useEffect(() => {
    //console.log("notifications: ", notification);
  }, [notification]);

  let bodyElem;
  if (window && document) {
    bodyElem = document.getElementsByTagName("body")[0];
  }

  const toggleSiteNav = (val) => {
    setSitenavToggle(val);
  };
  const toggleOffcanvas = (val, child, title) => {
    setOffcanvasToggle(val);
    setOcChild(child ? child : "");
    setOcTitle(child && title ? title : "");
  };

  const windowNavigation = (event) => {
    toggleSiteNav(false);
    toggleOffcanvas(false, null, null);
  };

  const dologin = () => {
    setIsloggedin(true);
  };
  const dologout = () => {
    setIsloggedin(false);
  };

  const [sitenavToggle, setSitenavToggle] = useState(false);
  const [offcanvasToggle, setOffcanvasToggle] = useState(false);
  const [ocChild, setOcChild] = useState("");
  const [ocTitle, setOcTitle] = useState("");
  const [isWindowPopAttached, setIsWindowPopAttached] = useState(false);
  const [isloggedin, setIsloggedin] = useState(true);
  const userLogggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [userProfileData, setuserProfileData] = useState("");
  const userData = useSelector((state) => state.users.userData);

  //console.log("userData ::::", JSON.parse(JSON.stringify(userData)));

  React.useEffect(() => {
    setIsloggedin(userLogggedIn);
  }, [userLogggedIn]);

  React.useEffect(() => {
    if (window && document && !isWindowPopAttached) {
      setIsWindowPopAttached(true);
      window.addEventListener("popstate", windowNavigation);
    }
    if ((sitenavToggle || offcanvasToggle) && bodyElem) {
      _AddClass(bodyElem, "overflow-hidden");
    } else {
      _RemoveClass(bodyElem, "overflow-hidden");
    }
  }, [sitenavToggle, offcanvasToggle]);

  const closeCanvas = () => {
    setOffcanvasToggle(false);
  };
  React.useMemo(() => {
    if (localStorage.getItem("token")) {
      dispatch(getProfileData());
    }
  }, []);

  React.useMemo(() => {
    setuserProfileData(userData);

    // logRocket
    if (process.env.REACT_APP_ENVIRONMENT.trim() == "prod") {
      console.log(
        "process.env.REACT_APP_ENVIRONMENT.trim()",
        process.env.REACT_APP_ENVIRONMENT.trim() == "prod"
      );
      LogRocket.init("efhkxm/tkl");
      LogRocket.identify(userData.email, { userData });
    }

    //Intercom
    window.intercomSettings = {
      api_base: "https://api-iam.intercom.io",
      app_id: "bfs7f7yw",
      name:
        (userData?.firstName || "") +
        (" " + userData?.middleName || "") +
        (" " + userData?.lastName || ""), // Full name
      email: userData?.email || "", // Email address
      created_at: Date.now(), // Signup date as a Unix timestamp
    };

    // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/bfs7f7yw'
    (function () {
      var w = window;
      var ic = w.Intercom;
      if (typeof ic === "function") {
        ic("reattach_activator");
        ic("update", w.intercomSettings);
      } else {
        var d = document;
        var i = function () {
          i.c(arguments);
        };
        i.q = [];
        i.c = function (args) {
          i.q.push(args);
        };
        w.Intercom = i;
        var l = function () {
          var s = d.createElement("script");
          s.type = "text/javascript";
          s.async = true;
          s.src = "https://widget.intercom.io/widget/bfs7f7yw";
          var x = d.getElementsByTagName("script")[0];
          x.parentNode.insertBefore(s, x);
        };
        if (document.readyState === "complete") {
          l();
        } else if (w.attachEvent) {
          w.attachEvent("onload", l);
        } else {
          w.addEventListener("load", l, false);
        }
      }
    })();

    //RollBar
    // Set the person data and custom data to be sent with all errors for this notifier.
    if (process.env.REACT_APP_ENVIRONMENT.trim() == "prod") {
      console.log("rollbar runnning in prod APP.js");
      // LogRocket.getSessionURL((sessionURL) => {
      //   Rollbar.configure({
      //     transform: function (obj) {
      //       obj.sessionURL = sessionURL;
      //     },
      //   });
      // });
      // Rollbar.configure({
      //   payload: {
      //     person: {
      //       id: userData?.userId,
      //       username:
      //         (userData?.firstName || "") +
      //         (" " + userData?.middleName || "") +
      //         (" " + userData?.lastName || ""),
      //       email: userData.email,
      //     },
      //     custom: {
      //       userData: userData,
      //     },
      //   },
      // });
    }
  }, [userData]);

  return (
    <React.Fragment>
      {/* <TKLErrorBoundary> */}
      <React.Suspense fallback={<div>Loading~~~~~</div>}>
        <Provider store={store}>
          {/* <ComponentSiteheader toggleSiteNav={toggleSiteNav} />
          <MainRoutes isloggedin={isloggedin} dologin={dologin}></MainRoutes>
          <ComponentSitefooter /> */}
          {/* <ComponentSitenav
            isToggled={sitenavToggle}
            toggleSiteNav={toggleSiteNav}
            toggleOffcanvas={toggleOffcanvas}
          />
          <ComponentOffcanvas
            isToggled={offcanvasToggle}
            toggleOffcanvas={toggleOffcanvas}
            title={ocTitle}
          ></ComponentOffcanvas> */}
          <ContextUser.Provider
            value={{
              isloggedin: isloggedin,
              dologout: dologout,
              history: history,
              userProfileData: userProfileData,
            }}
          >
            <ContextOffcanvas.Provider value={toggleOffcanvas}>
              <>
                <div className="root-app">
                  {isloggedin && !IS_UNDER_MAINTENANCE ? (
                    history?.location?.hash.includes("external-catalog") ||
                    history?.location?.hash.includes("external-catalog") ? (
                      <ComponentSiteheaderTKL />
                    ) : (
                      <ComponentSiteheader
                        toggleSiteNav={toggleSiteNav}
                        notification={notification}
                      />
                    )
                  ) : !IS_UNDER_MAINTENANCE ? (
                    <ComponentSiteheaderTKL />
                  ) : (
                    ""
                  )}
                  <MainRoutes
                    isUnderMaintenance={IS_UNDER_MAINTENANCE}
                    isloggedin={isloggedin}
                    dologin={dologin}
                  ></MainRoutes>
                  {isloggedin ? (
                    history?.location?.hash.includes("external-catalog") ||
                    history?.location?.hash.includes("external-catalog") ? (
                      <ComponentSitefooterTKL />
                    ) : (
                      <ComponentSitefooter />
                    )
                  ) : (
                    <ComponentSitefooterTKL />
                  )}
                </div>
                {(isloggedin &&
                  !history?.location?.hash.includes("external-catalog")) ||
                history?.location?.hash.includes("external-catalog") ? (
                  <>
                    <div className="root-offcanvas">
                      {!IS_UNDER_MAINTENANCE ? (
                        <ComponentSitenav
                          isToggled={sitenavToggle}
                          toggleSiteNav={toggleSiteNav}
                          toggleOffcanvas={toggleOffcanvas}
                        />
                      ) : (
                        ""
                      )}
                      <ComponentOffcanvas
                        isToggled={offcanvasToggle}
                        toggleOffcanvas={toggleOffcanvas}
                        title={ocTitle}
                      >
                        {ocChild === "OffcanvasCourseSettings" ? (
                          <Suspense fallback={<ComponentContentLoader />}>
                            <OffcanvasCourseSettings />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                        {ocChild === "OffcanvasChangeEmail" ? (
                          <Suspense fallback={<ComponentContentLoader />}>
                            <OffcanvasChangeEmail />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                        {ocChild === "OffcanvasChangePassword" ? (
                          <Suspense fallback={<ComponentContentLoader />}>
                            <OffcanvasChangePassword />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                        {ocChild === "OffcanvasCreateCohort" ? (
                          <Suspense fallback={<ComponentContentLoader />}>
                            <OffcanvasCreateCohort />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                        {ocChild === "OffcanvasCatalogFilters" ? (
                          <Suspense fallback={<ComponentContentLoader />}>
                            <OffcanvasCatalogFilters />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                        {ocChild === "OffcanvasUserAccount" ? (
                          <Suspense fallback={<ComponentContentLoader />}>
                            <OffcanvasUserAccount closeCanvas={closeCanvas} />
                          </Suspense>
                        ) : (
                          <></>
                        )}
                      </ComponentOffcanvas>
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            </ContextOffcanvas.Provider>
          </ContextUser.Provider>
        </Provider>
      </React.Suspense>

      {/* </TKLErrorBoundary> */}
      
    </React.Fragment>
  );
}

// const mapStateToProps = (state) => {
//   return {
//     isLoading: state.coursehome.isLoading,
//   };
// };
// const mapDispatchToProps = (dispatch) => {
//   return {};
// };
// export default connect(mapStateToProps, mapDispatchToProps)(App);
export default App;
