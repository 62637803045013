import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL_COHORT } from "../../api/tklEnvironments";
import {
  showFirstValidationErrorFromAPI,
  hideLoader,
} from "../../components/utility/ReUsableFunctions";
export const getCourseCohort = (programType, nextLink) => {
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let url = `${URL_COHORT}/get-courses-instructor-review?programType=${programType}`;    
    if (nextLink) {
      url = nextLink;
    }
    axios
      .get(url)
      .then(function (response) {
        if (response?.data?.data) {
          let responseData = response?.data;

          let coursesForInstructorReview = responseData?.data?.filter(
            (item) => {
              return item?.programName === "Comprehensive Course Program";
            }
          );
          responseData.data = coursesForInstructorReview;
          dispatch({
            type: types.GET_COURSES_INSTRUCTOR_REVIEW,
            payload: responseData,
          });
        }

        hideLoader(initialTime);
      })
      .catch(function (error) {
        console.error("..error", error);

        hideLoader(initialTime);
      });
  };
};

export const getActiveUsersPoints = (userSlug, courseSlug) => {
  return (dispatch) => {
    dispatch({ type: types.SHOW_LOADER });
    axios
      .get(
        `${URL_COHORT}/get-score-for-given-course-user?cohortCourseSlug=${courseSlug}&userSlug=${userSlug}`
      )
      .then(function (response) {
        dispatch({ type: types.HIDE_LOADER });
        if (response?.data) {
          dispatch({
            type: types.USER_POINTS,
            payload: response?.data?.response,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        console.error("..error", error);
      });
  };
};

export const updateUserData = (
  userSlug,
  courseSlug,
  result,
  courseData,
  programType,
  userSlug2,
  pageNumber,
  programName,
  sklSlug,
) => {
  let url
  if(programName === "micro" || programName === "kidvision"){
    url = `update-${programName}-user-result-status?cohortCourseSlug=${courseSlug}&userSlug=${userSlug}&result=${result}&schoolSlug=${sklSlug}`;
  }else{
    url = `update-user-result-status?cohortCourseSlug=${courseSlug}&userSlug=${userSlug}&result=${result}`
  }
  return (dispatch) => {
    axios
      .put(
        `${URL_COHORT}/${url}`
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Result Successfully Updated",
          });
        }
        dispatch(
          getActiveUsersProgress(courseData, programType, userSlug2, pageNumber, programName, courseSlug)
        );
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};

export const getActiveUsersProgress = (
  courseData,
  programType,
  userSlug,
  pageNo,
  programName,
  courseSlug1,
) => {
  return (dispatch) => {
    // console.log(".....courseData", courseData);
    dispatch({ type: types.SHOW_LOADER });
    let courseSlug = ["gplc", "micro", "kidvision"].includes(programType)
      ? `cohortCourseSlug=${courseData?.courseSlug}`
      : `cohortCourseSlug=${courseData?.slug}`;
    let userSlugParams = userSlug ? `&&userSlug=${userSlug}` : "";
    let pageNumber = pageNo ? `&&page=${pageNo}` : "";
    let apiSort = `&sortBy=completedAt&orderBy=DESC`;
    axios
      .get(
       // `${URL_COHORT}/get-active-users-progress-for-facilitators?cohortCourseSlug=codeigniter&&programType=micro${userSlugParams}${pageNumber}${apiSort}`
       `${URL_COHORT}/get-active-users-progress-for-facilitators?${programType ? courseSlug : "cohortCourseSlug="+courseSlug1}&&programType=${programType ? programType : programName}${userSlugParams}${pageNumber}${apiSort}`
      )
      .then(function (response) {
        if (response?.data) {
          dispatch({
            type: types.ACTIVE_USER_PROGRES,
            payload: response?.data?.response,
          });
        }
        dispatch({ type: types.HIDE_LOADER });
      })
      .catch(function (error) {
        dispatch({ type: types.HIDE_LOADER });
        console.error("..error", error);
      });
  };
};

export const getReviewActivity = (
  courseData,
  programType,
  userSlug,
  activityType,
  pageNo = 1
) => {
  return (dispatch) => {
    sessionStorage.setItem("pageNo", pageNo);
    let userSlugData = userSlug ? `&&userSlug=${userSlug}` : "";
    let courseSlug = ["gplc", "micro", "kidvision"].includes(programType)
      ? `courseSlug=${courseData?.courseSlug}`
      : `cohortCourseId=${courseData?.courseDisplayId}`;

    let marketLocationData = ["gplc", "micro", "kidvision"].includes(programType)
      ? `&&cohortId=${courseData?.cohortDisplayId}`
      : `&&marketLocation=${courseData?.marketLocation?.slug}`;

    let pageNumber = `&&page=${pageNo}`;
    if (!["undefined", "null", null, undefined].includes(courseData)) {
      dispatch({ type: types.IS_LOADING, payload: true });
      axios
        .get(
          `${URL_COHORT}/get-review-activity?${courseSlug}${marketLocationData}&&programType=${programType}${userSlugData}
        ${activityType && `&&stepTypeSlug=${activityType}`}${pageNumber}`
        )
        .then(function (response) {
          if (response.data) {
            if (response.data.data.length > 0) {
              dispatch({
                type: types.GET_ACTIVITY_REVIEW_DATA,
                payload: response.data,
              });
            } else {
              dispatch({
                type: types.GET_ACTIVITY_REVIEW_DATA,
                payload: response.data,
              });

              if (pageNo != 1) {
                dispatch(
                  getReviewActivity(
                    courseData,
                    programType,
                    userSlug,
                    activityType,
                    1
                  )
                );
              }
            }
          }
          dispatch({ type: types.IS_LOADING, payload: false });
        })
        .catch(function (error) {
          dispatch({ type: types.IS_LOADING, payload: false });
          console.error("..error", error);
        });
    }
  };
};

export const getUserCertificates = (
  courseData,
  programType,
  userSlug,
  pageNumber = 1
) => {
  return (dispatch) => {
    let courseSlug = ["gplc", "micro", "kidvision"].includes(programType)
      ? `cohortCourseSlug=${courseData?.courseSlug}`
      : `cohortCourseSlug=${courseData?.slug}`;
    let userSlugParams = userSlug ? `&&userSlug=${userSlug}` : "";
    if(courseSlug){
    axios
      .get(
       `${URL_COHORT}/get-course-completed-users?${courseSlug}&programType=${programType}&isPaginated=1${userSlugParams}&page=${pageNumber}&sortBy=lastName`
      )
      .then(function (response) {
        if (response?.data) {
          let responseData = response?.data?.response;
          dispatch({
            type: types.USER_CERTIFICATION_DATA,
            payload: responseData,
            label: "userCompletionData",
          });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
    }
  };
};

export const updateExtension = (extensionDays, extensionData) => {
  return async (dispatch) => {
    const marketLocationSlugSession =
      sessionStorage.getItem("marketLocationSlug");

    let courseDataFromSession_Index = extensionData?.selectedCohort.findIndex(
      (courseData) =>
        courseData?.marketLocation?.slug === marketLocationSlugSession &&
        courseData?.cohortDisplayId == sessionStorage.getItem("cohortCourseID")
    );
    let payload = {
      cohortDisplayId: sessionStorage.getItem("cohortCourseID"),
      userSlug: extensionData?.userSlug,
      noOfDaysTobeExtended: extensionDays,
    };

    await axios
      .put(`${URL_COHORT}/update-request-extension`, payload)
      .then((response) => {
        if (response.data) {
          dispatch({
            type: types.API_SUCCESS,
            payload: "Date Extended",
          });

          dispatch(
            getActiveUsersProgress(
              extensionData?.selectedCohort[courseDataFromSession_Index],
              // extensionData?.courseData,
              extensionData?.programType,
              extensionData?.userSlug
            )
          );
          return Promise.resolve();
        }
      })
      .catch((error) => {
        console.error("...errror", error);
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }

        return Promise.reject();
      });
  };
};

export const getUsersForMarketLocation = (
  courseSlug,
  marketLocationSlug,
  link,
  searchValue = ""
) => {
  return (dispatch) => {
    let url = "";
    if (link) {
      url = link;
    } else if (searchValue) {
      console.log("searchValue", searchValue);
      if(localStorage.getItem("kidVisionSelected") || localStorage.getItem("microSelected")){
        url = `${URL_COHORT}/get-users-by-course/${courseSlug}?searchBy=name&searchValue=${searchValue}`;
      }else{
        url = `${URL_COHORT}/get-users-by-course/${courseSlug}/${marketLocationSlug}?searchBy=name&searchValue=${searchValue}`;
      }
    } else {
      if(localStorage.getItem("kidVisionSelected") || localStorage.getItem("microSelected")){
        url = `${URL_COHORT}/get-users-by-course/${courseSlug}`;
      }else{
        url = `${URL_COHORT}/get-users-by-course/${courseSlug}/${marketLocationSlug}`;
      }
    }
    return axios
      .get(url)
      .then(function (response) {
        if (response?.data) {
          let responseData = response?.data?.data;
          let res = [];
          res.push({ name: "All Users", userSlug: "" });
          responseData?.forEach((item) => {
            let newData = {
              name: item?.name,
              userSlug: item?.slug,
              email: item?.email,
            };
            res.push(newData);
          });
          res = {
            links: response?.data?.links,
            meta: response?.data?.meta,
            data: res,
          };
          return Promise.resolve(res);
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        return Promise.reject(false);
      });
  };
};

export const resetActivityByInstructor = (
  activityData,
  courseData,
  programType,
  activityType,
  pageNo
) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let apiUrl = `step-reset?isIntroduction=${activityData?.isIntro ? 1 : 0}&${
      activityData?.isIntro ? "introStepSlug=" : "stepSlug="
    }${activityData?.slug}&userSlug=${activityData?.userSlug}`;
    return await axios
      .put(`${URL_COHORT}/${apiUrl}`)
      .then(function (response) {
        if (response?.data) {
          dispatch(
            getReviewActivity(
              courseData,
              programType,
              activityData?.userSlug,
              activityType,
              pageNo
            )
          );
          hideLoader(initialTime);
          return Promise.resolve(true);
        }
      })
      .catch(function (error) {
        console.error("..error", error);
        if (error.response?.data?.errors) {
          let message = showFirstValidationErrorFromAPI(
            error.response?.data?.errors
          );
          dispatch({
            type: types.API_ERROR,
            payload: message,
          });
        }
        hideLoader(initialTime);
        return Promise.reject(false);
      });
  };
};
