import { types } from "../../reducers/types";
import axios from "axios";
import { store } from "../../App";
import { URL } from "../../api/tklEnvironments";
import {
  hideLoader,
  avtrBannerImagesDataPrepare,
  streamMediaURL,
} from "../../components/utility/ReUsableFunctions";
import { getTilePositionData } from "../../actions/DashboardActions/InstructionalCoachActions";
export const getPositionTitles = () => {
  return (dispatch) => {
    axios
      .get(`${URL}/get-positions?isPaginated=0`)
      .then(function (response) {
        if (response.data) {
          let modifiedData = [];
          //response.data?.data
          modifyDataFromAPI(response.data, modifiedData);
          dispatch({ type: types.POSITION_TITLES, payload: modifiedData });
        }
      })
      .catch(function (error) {
        console.error("..error", error);
      });
  };
};
const modifyDataFromAPI = (data, modifiedData) => {
  data &&
    data.forEach((item) => {
      if (item.status === "ACTIVE") {
        let newData = {};
        newData["name"] = item["title"] || "";
        newData["slug"] = item["slug"] || "";
        newData["status"] = item["status"] || "";
        modifiedData.push(newData);
      }
    });
};
export const saveUserProfile = (data) => {
  return (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    let userData = store && store.getState().users.userData;
    let dataPayload = {};
    dataPayload["firstName"] = data.firstName;
    dataPayload["middleName"] = data.middleName;
    dataPayload["lastName"] = data.lastName;
    dataPayload["email"] = userData?.email;
    dataPayload["gradeLevelSlugs"] = [];
    dataPayload["positionSlugs"] = [];
    if (
      userData?.positionTitles.findIndex((item) => item.name === "Other") !== -1
    ) {
      dataPayload["requestedPositionTitle"] = data?.positionTitlesOther || "";
    } else {
      dataPayload["requestedPositionTitle"] = "";
    }
    if (userData?.subArea.findIndex((item) => item.name === "Other") !== -1) {
      dataPayload["requestedSubjectArea"] = data?.subAreaOther || "";
    } else {
      dataPayload["requestedSubjectArea"] = "";
    }
    dataPayload["subjectAreasSlugs"] = [];
    dataPayload["profileBannerSlug"] = userData.bannerImg?.slug || "";
    dataPayload["profileAvatarSlug"] = userData.avatarImg?.slug || "";
    dataPayload["school"] = data?.school;
    dataPayload["isSchoolPrivate"] = data?.schoolPrivate;
    dataPayload["district"] = data.district;
    dataPayload["isDistrictPrivate"] = data?.districtPrivate;
    dataPayload["isCountryPrivate"] = data?.countryPrivate;
    dataPayload["stateSlug"] = userData?.state?.slug || "";
    dataPayload["countrySlug"] = userData?.country?.slug || "";

    dataPayload["isStatePrivate"] = data?.statesPrivate;
    dataPayload["aboutYourself"] = userData?.aboutUser;
    dataPayload["interestsAndHobbies"] = data?.hobbies;
    dataFormatPrepareForAPI(
      userData?.positionTitles,
      "positionSlugs",
      dataPayload
    );
    dataFormatPrepareForAPI(
      userData?.subArea,
      "subjectAreasSlugs",
      dataPayload
    );
    dataFormatPrepareForAPI(
      userData?.gradeLevel,
      "gradeLevelSlugs",
      dataPayload
    );
    let pointsResult = calculatePointsForProfile(userData, data, dataPayload);
    dataPayload["profileCompletedPercentage"] = Math.floor(pointsResult) || 0;
    console.info('isRedirect', data?.redirect)
    if (data?.redirect) {
      axios
      .put(`${URL}/save-user-details`, dataPayload)
      .then(function (response) {
        hideLoader(initialTime);
        dispatch({
          type: types.API_SUCCESS,
          payload: "User Details Saved",
          pathName: "/profileData",
        });
      })
      .catch(function (error) {
        hideLoader(initialTime);
      });
    } else {
      hideLoader(initialTime);
    }
    
  };
};

const calculatePointsForProfile = (userData, fieldData, dataPayload) => {
  let totalPoints = 0;
  Object.keys(dataPayload)?.forEach((data) => {
    if (
      data !== "middleName" &&
      data !== "email" &&
      data !== "requestedPositionTitle" &&
      data !== "isSchoolPrivate" &&
      data !== "isDistrictPrivate" &&
      data !== "isCountryPrivate" &&
      data !== "isStatePrivate" &&
      data !== "requestedSubjectArea"
    ) {
      switch (typeof dataPayload[data]) {
        case "string":
          if (dataPayload[data] !== "") totalPoints++;
          break;
        case "object":
          if (
            Array.isArray(dataPayload[data]) &&
            dataPayload[data][0]?.slug !== ""
          ) {
            totalPoints++;
          } else {
            if (dataPayload[data]?.slug !== "") {
              totalPoints++;
            }
          }
          break;
        default:
          break;
      }
    }
  });
  return (100 / 13) * totalPoints;
};
const dataFormatPrepareForAPI = (item, label, dataPayload) => {
  item.forEach((element) => {
    dataPayload[`${label}`].push(element?.slug);
  });
};
export const formDataUserData = (payload, label, allOptions, checked) => {
  return (dispatch) => {
    dispatch({
      type: types.MULTISELECT_FORM_DATA,
      payload: payload,
      label: label,
      allOptions: allOptions,
      checked: checked,
    });
  };
};

export const createProfileSingleSelection = (payload, label) => {
  return (dispatch) => {
    dispatch({
      type: types.SINGLE_SELECTION_DRPDOWN,
      payload: payload,
      label: label,
    });
  };
};

export const getProfileData = (otherUserSlug, isTilePosition, showLoader) => {
  return (dispatch) => {
    if (localStorage.getItem("token")) {
      if (isTilePosition) {
        dispatch(getTilePositionData()); 
      }

      let initialTime = performance.now();
      showLoader && dispatch({ type: types.SHOW_LOADER });

      let urlItem = `get-user-bio/${otherUserSlug ? otherUserSlug : ""}`;
      axios
        .get(`${URL}/${urlItem}`)
        .then(function (response) {
          const roles = [
            // "CONTENT ADMIN", //their courses only
            // "CONTENT AUTHOR", //all aproved disabled
            // "TKL MEMBER (FREE USER)",
            "COMPREHENSIVE PROGRAM MEMBER (PAID USER)",
            "MICRO COURSE PROGRAM MEMBER (PAID USER)",
            "GPLC PROGRAM MEMBER (PAID USER)",
            // "DISTRICT ADMIN",
            //"SYSTEM ADMIN",
            // "TKL ACCOUNT MANAGER",
            // "TKL FACILITATOR",
            // "DISTRICT FACILITATOR",
            // "SCHOOL FACILITATOR",
            // "SCHOOL ADMIN",
          ];
          let profileData = {};
          let responseData = response.data.response;
          profileData["userId"] = responseData?.userId || null;
          profileData["firstName"] = responseData?.firstName || "";
          profileData["middleName"] = responseData?.middleName || "";
          profileData["lastName"] = responseData?.lastName || "";
          profileData["email"] = responseData?.email || "";
          profileData["roles"] = responseData?.roles || "";
          profileData["school"] = responseData?.userDetails?.school || "";
          profileData["schoolPrivate"] =
            responseData?.userDetails?.isSchoolPrivate || false;
          profileData["district"] = responseData?.userDetails?.district || "";
          profileData["districtPrivate"] =
            responseData?.userDetails?.isDistrictPrivate || false;
          profileData["statesPrivate"] =
            responseData?.userDetails?.isStatePrivate || false;
          profileData["countryPrivate"] =
            responseData?.userDetails?.isCountryPrivate || false;

          profileData["aboutUser"] =
            responseData?.userDetails?.aboutYourself || "";
          profileData["userSlug"] = responseData?.userSlug || "";
          profileData["hobbies"] =
            responseData?.userDetails?.interestsAndHobbies || "";

          let modifiedData = [];
          modifyDataFromAPI(responseData?.positions, modifiedData);
          profileData["positionTitles"] = modifiedData || [];
          profileData["positionTitlesOther"] =
            responseData?.otherRequestedPosition?.title || "";

          profileData["subArea"] = responseData?.subjectAreas || [];
          profileData["subAreaOther"] =
            responseData?.otherRequestedSubjectArea?.title || "";
          profileData["country"] = responseData?.userDetails?.country || {};
          //  console.log("country from profile data", responseData?.userDetails?.country); 
           if(responseData?.userDetails?.country){
            sessionStorage.setItem("country", JSON.stringify(responseData?.userDetails?.country));
            }else{
              sessionStorage.setItem("country", JSON.stringify({}));
            }

          profileData["state"] = responseData?.userDetails?.state || {};
          //  console.log("state from profile data", responseData?.userDetails?.state);
            if(responseData?.userDetails?.state){
              sessionStorage.setItem("state", JSON.stringify(responseData?.userDetails?.state));
            }else{
              sessionStorage.setItem("state", JSON.stringify({}));
            }

          profileData["gradeLevel"] =
            responseData?.gradeLevels?.sort(
              (a, b) =>
                //a?.index > b?.index ? 1 : b?.index > a?.index ? -1 : 0
                a?.index - b?.index
            ) || [];

          let media_URL_AVTRIMG = streamMediaURL(
            responseData?.profileAvatar?.slug
          ); //`${URL}/stream-media/${responseData?.profileAvatar?.slug}`;
          // alert(media_URL_AVTRIMG);
          let avatarImg = {};
          profileData["avatarImg"] =
            avtrBannerImagesDataPrepare(
              avatarImg,
              responseData?.profileAvatar,
              media_URL_AVTRIMG
            ) || {};
          let bannerImg = {};
          let media_URL_BANNERIMG = streamMediaURL(
            responseData?.profileBanner?.slug
          ); //`${URL}/stream-media/${responseData?.profileBanner?.slug}`;
          profileData["bannerImg"] =
            avtrBannerImagesDataPrepare(
              bannerImg,
              responseData?.profileBanner,
              media_URL_BANNERIMG
            ) || {};
          profileData["joinedTKL"] = responseData?.joinedAt || new Date();

          profileData["postsCount"] = responseData?.postsCount || 0;
          profileData["profileView"] = responseData?.profileView || 0;

          profileData["lastAccessedAt"] =
            responseData?.lastAccessedAt || new Date();
          profileData["points"] = responseData?.profileCompletedPercentage || 0;
          profileData["facilitatorData"] = responseData?.instructionalCoachData;
          profileData["paidUserData"] = responseData?.paidUserData;
          profileData["contentAdminData"] = responseData?.contentAdminStats;
          profileData["contentAuthorData"] = responseData?.contentAuthor;
          profileData["accountManagerData"] = responseData?.accountManagerStats;
          profileData["systemAdminStats"] = responseData?.systemAdminStats;
          profileData["schoolFacilitatorStats"] =
            responseData?.schoolFacilitatorStats;
          profileData["districtFacilitatorStats"] =
            responseData?.districtFacilitatorStats;
          profileData["followedByCount"] = responseData?.followersCount || 0;
          profileData["followingCount"] = responseData?.followingCount || 0;
          profileData["isFollowed"] = responseData?.isFollowed || false;
          profileData["isBlocked"] = responseData?.isBlocked || false;
          profileData["isConnected"] = responseData?.isConnected || false;
          profileData["IsInvitationPending"] =
            responseData?.IsInvitationPending || false;

          if (otherUserSlug) {
            dispatch({
              type: types.GET_OTHER_PROFILE_DATA,
              payload: profileData,
              label: "otherUserData",
            });
          } else {
            dispatch({ type: types.GET_PROFILE_DATA, payload: profileData });
          }
          hideLoader(initialTime);
        })
        .catch(function (error) {
          console.log("....error", error);
          hideLoader(initialTime);
        });
    }
  };
};

// Api to get the programId
export const getProgramDetailsApi = () => {
  return async (dispatch) =>{
    await axios.get(`${URL}/program`).then((res) => {
      localStorage.setItem("programData",JSON.stringify(res?.data?.data))
      dispatch({
        type: types.GET_PROGRAM_DATA,
        payload: res?.data?.data,
      });
    }).catch((err) => {
      console.log("Error Program api",err)
    })
  }
}

// Api to get the micro/ kidvision catalog description data 
export const getMicroDescriptioncatalogData = (pageNumber, selectedRadio) => {
  let selected = "";
  if(selectedRadio === "Kid Vision" || (localStorage.getItem("selectedRadioValue") === "Kid Vision")){
    selected = "kidvision";
  }else{
    selected = "micro"
  }
  // localStorage.removeItem("selectedRadioValue");
  return async (dispatch) =>{
    axios.get(`${URL}/get-micro-catalogue-information-data/${selected}/?isPaginated=1&page=${pageNumber? pageNumber : 1}`).then((res) => { 
      const apiData = res?.data?.data?.map((element, index) => {
           element["type1"]= "AccountCatalogueDescription";
           let pageNo;
           if(pageNumber){
            pageNo = ((15*(pageNumber-1))+index+1)
           }else{
            pageNo = index+1;
          }
           element["serial"]= pageNo;
           return element
        })
      dispatch({
        type: types.GET_MICRO_CATALOG_DESCRIPTION_DATA,
        payload: {
          apiData: apiData,
          lastPage: res?.data?.last_page,
        }
      });
    }).catch ((err) => {
      console.log("error",err)
    })
  }
}

export const getDescriptioncatalogData = (pageNumber, selectedRadio) => {
  let selected = "";
  if(selectedRadio === "Kid Vision" || (localStorage.getItem("selectedRadioValue") === "Kid Vision")){
    selected = "kidvision";
  }else{
    selected = "micro"
  }
  return async (dispatch) =>{
    axios.get(`${URL}/get-micro-catalogue-information-data/${selected}/?isPaginated=0&page=${pageNumber? pageNumber : 1}`).then((res) => { 
      const apiData = res?.data?.map((element, index) => {
           element["type1"]= "AccountCatalogueDescription";
           let pageNo;
           if(pageNumber){
            pageNo = ((15*(pageNumber-1))+index+1)
           }else{
            pageNo = index+1;
          }
           element["serial"]= pageNo;
           return element
        })
      dispatch({
        type: types.GET_DESCRIPTION_DATA,
        payload: {
          apiData: apiData,
          lastPage: res?.data?.last_page,
        }
      });
    }).catch ((err) => {
      console.log("error",err)
    })
  }
}

export const changePassword = (data, history) => {
  return (dispatch) => {
    let dataToSend = {};
    dataToSend["currentPassword"] = data?.existingPassword;
    dataToSend["newPassword"] = data?.newPassword;
    dataToSend["confirmPassword"] = data?.confirmPassword;
    axios
      .put(`${URL}/change-password`, dataToSend)
      .then(function (response) {
        dispatch({
          type: types.API_SUCCESS,
          payload: "Password Updated. Please re-login to continue.",
        });
        setTimeout(() => {
          localStorage.removeItem("token");
          localStorage.removeItem("userRole");
          localStorage.removeItem("informationSheet");
          localStorage.removeItem("cartData");
          sessionStorage.clear();
          history?.push("/");
          history?.go(0);
        }, 800);
      })
      .catch(function (error) {
        console.log("...error", error);
        let errorMessage = error?.response?.data?.errors;
        if (errorMessage?.currentPassword) {
          dispatch({
            type: types.API_ERROR,
            payload: "Incorrect Current Password ",
          });
        } else if (errorMessage?.newPassword) {
          if (
            errorMessage?.newPassword[0] ===
            "The current password and new password must not be same."
          ) {
            dispatch({
              type: types.API_ERROR,
              payload:
                "The current password and new password must not be same.",
            });
          } else {
            dispatch({
              type: types.API_ERROR,
              payload: "Please choose strong password.",
            });
          }
        } else {
          dispatch({
            type: types.API_ERROR,
            payload: "Please try again.",
          });
        }
      });
  };
};

export const getUserContentDeveloperCourses = (userSlug) => {
  return async (dispatch) => {
    let userSlugParam = userSlug ? "?userSlug=" + userSlug : "";
    await axios
      .get(`${URL}/get-created-course${userSlugParam}`)
      .then((response) => {
        let responseData = response.data?.response;
        responseData.forEach((data) => {
          let media_URL_AVTRIMG = streamMediaURL(data?.avatarImage?.slug); //`${URL}/stream-media/${data?.avatarImage?.slug}`;
          let avatarImg = {};
          data["avatarImage"] =
            avtrBannerImagesDataPrepare(
              avatarImg,
              data?.avatarImage,
              media_URL_AVTRIMG
            ) || {};
          let bannerImg = {};
          let media_URL_BANNERIMG = streamMediaURL(data?.bannerImage?.slug); // `${URL}/stream-media/${data?.bannerImage?.slug}`;
          data["bannerImage"] =
            avtrBannerImagesDataPrepare(
              bannerImg,
              data?.bannerImage,
              media_URL_BANNERIMG
            ) || {};
        });
        dispatch({
          type: types.USER_CONTENT_DEVELOPER_COURSES,
          payload: responseData,
        });
      })
      .catch((error) => {
        console.error("..error", error);
      });
  };
};

export const logOutUser = (history, isExternal) => {
  return async (dispatch) => {
    let initialTime = performance.now();
    dispatch({ type: types.SHOW_LOADER });
    sessionStorage.clear();
    if (localStorage.getItem("token")) {
      await axios
        .post(`${URL}/logout`)
        .then((response) => {
          localStorage.removeItem("token");
          localStorage.removeItem("userRole");
          localStorage.removeItem("informationSheet");
          localStorage.removeItem("cartData");
          dispatch({ type: types.ASSIGN_TOKEN, payload: "" });
          if (!isExternal) {
            setTimeout(() => {
              history.push("/");
              history.push("/loginPage");
            }, 0);
          }
          hideLoader(initialTime);

          dispatch({ type: types.GET_PROFILE_DATA, payload: {} });
          dispatch({
            type: types.GET_CART_DATA,
            payload: {},
          });
        })
        .catch((error) => {
          localStorage.removeItem("token");
          dispatch({ type: types.ASSIGN_TOKEN, payload: "" });
          if (!isExternal) {
            setTimeout(() => {
              history.push("/");
              history.push("/loginPage");
            }, 0);
          }

          hideLoader(initialTime);

          dispatch({ type: types.GET_PROFILE_DATA, payload: {} });
          dispatch({
            type: types.GET_CART_DATA,
            payload: {},
          });
        });
    }
  };
};
